@import '../../submodules/src/styles/badge-pill';
@import '../../submodules/src/styles/flex-grid';
@import '_variables';
@import 'element/_catalog-variables';
@import 'element/horizontal-list';

.wrapper-header-top {
  border-bottom: 1px solid var(--color-default-text-1);
  margin: 1rem 0;

  .title {
    text-align: center;
    font-size: 2rem;

    @media (@min-screen-md) {
      margin: 0;
    }
    @media (@max-screen-md) {
      margin-bottom: 0;
      margin-top: 0.5rem;
      font-size: 1.5rem;
      padding-top: 0;
    }
  }

  .internal_title {
    @media (@max-screen-md) {
      padding-bottom: 0.25rem;
    }
  }

  .line-clamp-3 {
    display: -webkit-box;
    @media (@max-screen-md) {
      -webkit-line-clamp: 2;
    }

    &.line-clamp-1-mobile {
      @media (@max-screen-md) {
        -webkit-line-clamp: 1;
      }
    }

    p {
      display: inline;
    }
  }

  @media (@max-screen-xs) {
    border-bottom: none;
  }
}

.catalog-filter-group-option:hover,
#catalog-desktop-sort li:hover {
  color: @color-primary-5;
}

.catalog-filter-group-option {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  @media (@min-screen-xs) {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
}

#catalog-mobile-filter {
  #catalog-mobile-filter-header {
    z-index: @header-z-index + 2;
  }

  #catalog-mobile-filter-body {
    z-index: @header-z-index + 1;
    height: 100%;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; // iOS smooth scroll / momentum

    #catalog-mobile-filter-content {
      padding: 5rem 1rem @mobile-bottom-spacing;
    }

    .loading {
      position: fixed;
      background: white;
      opacity: 0.5;
      top: 0;
      height: 100%;
      width: 100%;
    }
  }

  &.open {
    #catalog-mobile-filter-header,
    #catalog-mobile-filter-body {
      pointer-events: initial;
      right: 0;
    }

    #catalog-mobile-filter-body {
      box-shadow: @default-shadow;
    }
  }

  ul.catalog-mobile-filter-list {
    padding-left: 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;

      @media (@min-screen-xs) {
        line-height: normal;
      }

      a {
        flex: 1 1 auto;
        color: @color-default-4;
      }

      label {
        margin-bottom: 0;
      }
    }

    .badge-default {
      background-color: @color-default-2;
      color: @color-default-4;
      opacity: 0.7;
    }
  }

  ul.catalog-mobile-filter-sizes,
  ul#catalog-mobile-filter-colors {
    list-style: none;
    padding-left: 0;

    &:after {
      content: '';
      flex: 0 0 33%;
    }

    > li {
      flex: 0 0 33%;
      display: block;

      > label {
        display: block;
        cursor: pointer;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin: 0;
      }
    }
  }

  ul.catalog-mobile-filter-sizes {
    justify-content: space-between;

    > li {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      &:nth-child(3n + 1) {
        padding-right: 0.34rem;
      }

      &:nth-child(3n + 2) {
        padding-left: 0.16rem;
        padding-right: 0.16rem;
      }

      &:nth-child(3n) {
        padding-left: 0.34rem;
      }
    }

    > li > label {
      background-color: @color-default-2;
      transition: background-color @transition-duration @transition-curve;

      &:hover {
        background-color: @color-accent-1;
        color: white;
      }

      &.selected,
      &:active {
        background-color: @color-default-5;
        text-decoration: none;
        color: white;
      }
    }
  }
}

#catalog-description {
    overflow: hidden;

    :nth-child(n) {
        -webkit-transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
        transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }

    &.collapsed {
        :nth-child(n + 4) {
            overflow: hidden;
            height: 0;
            margin: 0;
            opacity: 0;
        }

        :nth-child(-n + 4) {
            height: 100%;
            opacity: 1;
        }
    }

    &.expanded {
        :nth-child(n) {
            height: 100%;
            opacity: 1;
        }
    }
}

#bottom-description-toggle {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
        cursor: pointer;
        text-decoration: underline;
        font-size: 14px;
    }

    .h-divider {
        background-color: @color-default-5;
        height: 1px;
        flex: 1;
    }
}

.product-added-to-cart {
  position: fixed;
  z-index: 5;
  width: 100%;
  padding: 10px 20px;
  background-color: white;
  margin-top: -1rem;
  font-size: 0.9rem;
}

