@import '../_variables';

.horizontal-list {
  position: relative;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-left: 1rem;
  padding-bottom: 1rem;
  margin-left: -1rem;
  width: calc(100% + 2rem);

  > * {
    flex: 0 0 auto;
    max-width: calc(100% - 2rem);
    margin-right: 1rem;
  }

  &::after {
    content: '';
    flex: 0 0 1px;
  }

  @media (@min-screen-sm) {
    padding-left: 0;
    margin-left: 0;
    width: 100%;

    &::after {
      content: none;
    }
  }
}

.horizontal-list-2 > * {
  max-width: calc(50% - 2rem);
}
